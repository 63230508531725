import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import { PageWrapper, TextWrapper, HeadingWrapper, FlexWrapper, FlexItem } from "../components/contentwrappers"

import { FaBuilding, FaPhone, FaEnvelope } from "react-icons/fa"

import ContactForm from "../components/contact-form/contact-form"
import Map from "../components/map/map"


import MapStyle from "../components/map/style.json"

const SecondPage = props => (
  <>
    <BackgroundImage
      fluid={props.data.royalCourtsImage.childImageSharp.fluid}
      alt="The Royal Courts of Justice"
      style={{ height: "100vh", width: "100vw", position: "fixed", top: "0", left: "0", zIndex: "-9" }}
    >
    </BackgroundImage>

    <Layout>
      <SEO title="Contact" />
      <PageWrapper>
        <HeadingWrapper>
          <h1><strong>Contact</strong> Us</h1>
        </HeadingWrapper>
        <TextWrapper>
          <FlexWrapper>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}><FaBuilding style={{ marginRight: "1em" }} />Address</strong> 1st Floor, Avenue House <br /> 42-44 Rosemary Street <br /> Belfast, BT1 1QE
              </div>
            </FlexItem >
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}><FaEnvelope style={{ marginRight: "1em" }} />Email</strong> <a style={{ color: "white" }} hRef="mailTo:info@lsnifa.com">info@lsnifa.com</a>
              </div>
            </FlexItem>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}><FaPhone style={{ marginRight: "1em" }} />Phone</strong> 028 9023 0696
              </div>
            </FlexItem >
          </FlexWrapper>
        </TextWrapper>

        <TextWrapper>
          <ContactForm></ContactForm>
        </TextWrapper>

        <TextWrapper >
          <div style={{ height: "50vh" }}>
            <Map
              id="myMap"
              options={{
                center: { lat: 54.600106, lng: -5.930220 },
                zoom: 14,
                styles: MapStyle,
              }}
              onMapLoad={map => {
                var marker = new window.google.maps.Marker({
                  position: { lat: 54.600106, lng: -5.930220 },
                  map: map,
                  title: 'LSNIFA'
                });
              }}
            />
          </div>
        </TextWrapper>

      </PageWrapper>

    </Layout>
  </>
)

export default SecondPage

export const pageQuery = graphql`
  query {
    royalCourtsImage: file(relativePath: { eq: "royal-courts-of-justice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5031, quality:60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`